import { useOktaAuth } from "@okta/okta-react";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import { useAlerts } from "best-common-react";
import React from "react";
import { useUser } from "../contexts/UserContext";
import { isIdTokenExpired } from "../utils/OAuthUtil";

interface HttpInterceptorProps {
  children: React.ReactNode;
}

const HttpInterceptor = ({ children }: HttpInterceptorProps) => {
  const { logout } = useUser();
  const { authState, oktaAuth } = useOktaAuth();
  const { addAlert } = useAlerts();

  axiosRetry(axios, {
    retries: 3, //attempts to retry
    retryCondition: (error) => {
      console.log("Error fetching from API will retry...");
      return !!error?.response && (error.response.status === 401 || error.response.status === 403);
    }, // only retry on unauthenticated errors to prevent retrying 500s unless that is wanted
    retryDelay: (retryCount) => retryCount * 1000, // wait 1 second between retrys
  });

  axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
    let accessToken = authState?.accessToken?.accessToken;
    let idToken = authState?.idToken?.idToken;

    const tokensNotPresent = !accessToken || !idToken;
    const idTokenExpired = !!authState?.idToken && isIdTokenExpired(authState.idToken);

    if (tokensNotPresent || idTokenExpired) {
      try {
        const newTokenInfo = await oktaAuth.token.renewTokens();
        accessToken = newTokenInfo.accessToken?.accessToken;
        idToken = newTokenInfo.idToken?.idToken;
      } catch (e) {
        addAlert({ type: "danger", text: "Error authenticating. Please log in again." });
        logout();
      }
    }

    if (config.url && config.headers) {
      if (config.url.indexOf("/oauth/token") < 0 || config.headers.addToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        config.headers["x-id-token"] = idToken ?? "";
      }
    }
    return config;
  });

  axios.interceptors.response.use(
    (resp: AxiosResponse) => {
      if (!resp) return null;
      return resp;
    },
    (err: AxiosError) => {
      if (!!err.response) {
        if (err.response.status === 401 || err.response.status === 403) {
          logout();
        }

        return Promise.reject({ message: err.response.data.error });
      }
      return Promise.reject(err);
    },
  );

  return <div>{children}</div>;
};

export default HttpInterceptor;
