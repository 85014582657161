import { createTheme, ThemeProvider } from "@mui/material";
import { AlertProvider, AlertsFromProvider, BCR, Loading } from "best-common-react";
import React, { lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { useUser } from "./contexts/UserContext";
import { MlbAuthColors, MlbAuthTheme } from "./theme/MlbAuthTheme";

const AuthenticatedApp = lazy(() => import("./AuthenticatedApp"));
const UnauthenticatedApp = lazy(() => import("./UnauthenticatedApp"));

const App = () => {
  const { loggedIn } = useUser();

  return (
    <Suspense fallback={<Loading type="mlb" />}>
      <BrowserRouter>{loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}</BrowserRouter>
    </Suspense>
  );
};

export default App;
