import { oktaAuth } from "../contexts/UserContext";
import { EYEBALL_IFRAME_ID, EyeballEntityType, ItemType } from "../constants/EyeballConstants";

export const isGameUsedBall = (itemType: string) =>
  itemType.localeCompare(ItemType.GAME_USED_BALL, "en", { sensitivity: "base" }) === 0;

export const isGameUsedBatOrBrokenBat = (itemType: string) =>
  itemType.localeCompare(ItemType.GAME_USED_BAT, "en", { sensitivity: "base" }) === 0 ||
  itemType.localeCompare(ItemType.GAME_USED_BROKEN_BAT, "en", { sensitivity: "base" }) === 0;

enum EyeballPostMsgType {
  AUTH = "auth",
  EDIT = "edit",
  RESET_MODALS = "reset_modals",
}

type AuthInfo = {
  accessToken: string;
  idToken: string;
  email: string;
};

type IframePostMsgBody = {
  type: EyeballPostMsgType;
  authInfo: AuthInfo;
  sessionAuthenticatorId?: number;
  gamePk?: number;
  scrollToEntityType?: EyeballEntityType;
  scrollToIndex?: number;
};

export const sendIframeAuthMsg = async () => {
  const authInfo = await getAuthInfo();
  const postMsgBody: IframePostMsgBody = {
    type: EyeballPostMsgType.AUTH,
    authInfo,
  };

  sendIframeMsg(postMsgBody);
};

export const sendIframeEditMsg = async (
  sessionAuthenticatorId: number,
  gamePk: number,
  scrollToEntityType: EyeballEntityType,
  scrollToIndex: number,
) => {
  const authInfo = await getAuthInfo();
  const postMsgBody: IframePostMsgBody = {
    type: EyeballPostMsgType.EDIT,
    authInfo,
    sessionAuthenticatorId,
    gamePk,
    scrollToEntityType,
    scrollToIndex,
  };

  sendIframeMsg(postMsgBody);
};

export const sendIframeResetModalsMsg = async () => {
  const authInfo = await getAuthInfo();
  const postMsgBody: IframePostMsgBody = {
    type: EyeballPostMsgType.RESET_MODALS,
    authInfo: authInfo,
  };

  sendIframeMsg(postMsgBody);
};

const getAuthInfo = async (): Promise<AuthInfo> => {
  const authenticated = await oktaAuth.isAuthenticated();

  if (authenticated) {
    const authState = oktaAuth.authStateManager.getAuthState();
    return {
      accessToken: authState?.accessToken?.accessToken ?? "",
      idToken: authState?.idToken?.idToken ?? "",
      email: authState?.idToken?.claims.email ?? "",
    };
  }

  throw new Error("Error getting token info. Please try again or contact MLB.");
};

const sendIframeMsg = (postMsgBody: IframePostMsgBody) => {
  const iframe = document.getElementById(EYEBALL_IFRAME_ID) as HTMLIFrameElement | null;
  iframe?.contentWindow?.postMessage(postMsgBody, window.MLBBest.envVariables.EYEBALL_APP_URL ?? "");
};
