import { StateCreator, create } from "zustand";
import { EyeballEntityType } from "../constants/EyeballConstants";
import { sendIframeEditMsg, sendIframeResetModalsMsg } from "../utils/EyeballUtil";

type EnterEditModePayload = {
  sessionAuthenticatorId: number;
  gamePk: number;
  eyeballEntityType: EyeballEntityType;
  editItemId: number;
  itemOrder: number;
};

type State = {
  iframeUrl: string;
  editingSessionAuthenticatorId: number;
  editMode: boolean;
  editItemId: number;
  eyeballSyncing: boolean;
  refreshReview: boolean;
};

type Actions = {
  enterEditMode: (enterEditModePayload: EnterEditModePayload) => Promise<void>;
  exitEditMode: () => Promise<void>;
  setEyeballSyncing: (eyeballSyncing: boolean) => void;
  setRefreshReview: (refreshReview: boolean) => void;
};

type GameReviewStore = State & Actions;

const initialState: State = {
  iframeUrl: window.MLBBest.envVariables.EYEBALL_APP_URL ?? "",
  editingSessionAuthenticatorId: -1,
  editMode: false,
  editItemId: -1,
  eyeballSyncing: false,
  refreshReview: false,
};

const createGameReviewStore: StateCreator<GameReviewStore, [], [], GameReviewStore> = (set) => ({
  ...initialState,
  enterEditMode: async (enterEditModePayload: EnterEditModePayload) => {
    set({ editMode: true, editItemId: enterEditModePayload.editItemId });
    await sendIframeEditMsg(
      enterEditModePayload.sessionAuthenticatorId,
      enterEditModePayload.gamePk,
      enterEditModePayload.eyeballEntityType,
      enterEditModePayload.itemOrder,
    );
  },
  exitEditMode: async () => {
    set({ editMode: false, editItemId: -1, eyeballSyncing: false, refreshReview: false }),
      await sendIframeResetModalsMsg();
  },
  setEyeballSyncing: (eyeballSyncing: boolean) => set({ eyeballSyncing }),
  setRefreshReview: (refreshReview: boolean) => set({ refreshReview }),
});

const useGameReviewStore = create<GameReviewStore>()((...a) => ({
  ...createGameReviewStore(...a),
}));

export default useGameReviewStore;
