import { Security } from "@okta/okta-react";
import React, { useEffect } from "react";
import HttpInterceptor from "./api/HttpInterceptor";
import { oktaAuth, UserProvider } from "./contexts/UserContext";
import { DiamondVideoPlayerProvider } from "./contexts/DiamondVideoPlayerContext";
import { AlertProvider, AlertsFromProvider, BCR, LoadingProvider } from "best-common-react";
import useGameReviewStore from "./store/GameReviewStore";
import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "./api/mlbauth/QueryClient";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PostMessage } from "./types/Eyeball";
import { MlbAuthColors, MlbAuthTheme } from "./theme/MlbAuthTheme";
import { createTheme, ThemeProvider } from "@mui/material";

type WrapperProviderProps = {
  children: React.ReactNode;
};

const WrapperProvider = ({ children }: WrapperProviderProps) => {
  const theme = createTheme({
    palette: {
      primary: {
        light: MlbAuthColors.blue,
        main: MlbAuthColors.blue,
        dark: MlbAuthColors.blue,
      },
    },
    typography: {
      fontFamily: "var(--bs-font-sans-serif)",
      button: {
        textTransform: "none",
        fontWeight: 600,
      },
    },
  });

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string): Promise<void> => {
    window.location.replace(originalUri);
  };

  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (event.origin !== window.MLBBest.envVariables.EYEBALL_APP_ORIGIN) return;

      const { setRefreshReview, setEyeballSyncing } = useGameReviewStore.getState();

      switch (event.data.action) {
        case PostMessage.REFRESH:
          setRefreshReview(true);
          break;
        case PostMessage.SYNCING:
          setEyeballSyncing(true);
          break;
      }
    };

    window.addEventListener("message", messageHandler);

    return () => window.removeEventListener("message", messageHandler);
  }, []);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <BCR theme={MlbAuthTheme}>
            <AlertProvider>
              <AlertsFromProvider />
              <UserProvider>
                <DiamondVideoPlayerProvider>
                  <LoadingProvider>
                    <HttpInterceptor>{children}</HttpInterceptor>
                    <ReactQueryDevtools initialIsOpen={false} />
                  </LoadingProvider>
                </DiamondVideoPlayerProvider>
              </UserProvider>
            </AlertProvider>
          </BCR>
        </ThemeProvider>
      </QueryClientProvider>
    </Security>
  );
};

export default WrapperProvider;
